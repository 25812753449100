import React, { Component } from 'react';
import moment from 'moment';
import styles from 'components/block-elements/live-score-match-item/live-score-match-item.module.less';
import MatchHelper from 'components/helpers/match-helper';
import LogoHelper from 'components/helpers/logo-helper';
import DateHelper from 'components/helpers/date-helper';
import TranslationStore from 'components/stores/translation-store';
import LinkWrapper from 'components/block-elements/link-wrapper/link-wrapper';


const EbettingType = [
  // {id: 0, name: 'Outright'}, 
  {id: 1, name: '1-x-2'},
  {id: 4, name: 'Over/Under'},
  {id: 8, name: 'Handicap'},
  {id: 10, name: 'Double Chance'},
  {id: 12, name: 'HT/FT'},
  {id: 17, name: 'Draw No Bet'},
  {id: 18, name: 'Both Teams To Score'},
  // {id: 32, name: 'Num Of Corners', values: null},
  {id: 999, name: 'Moneyline'}
];

export default class LiveScoreMatchItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      matchTime: '',
      awayScoreUpdated: false,
      homeScoreUpdated: false,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.setState({ matchTime: this.getMatchTimeFromProps() });
    this.timeoutId = setInterval(() => {
      if (this.state.matchTime !== this.getMatchTimeFromProps() && this._isMounted) {
        this.setState({ matchTime: this.getMatchTimeFromProps() });
      }
    }, 5000);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps?.timeLines?.latestEventMatchTime !== this.props.timeLines?.latestEventMatchTime ||
      prevProps?.timeLines?.latestEventStoppageTime !== this.props.timeLines?.latestEventStoppageTime ||
      prevProps?.timeLines?.latestEventType !== this.props.timeLines?.latestEventType ||
      prevProps.status !== this.props.status ||
      prevProps.timeLines?.latestEventPeriodType !== this.props.timeLines?.latestEventPeriodType ||
      prevProps.awayScore !== this.props.awayScore ||
      prevProps.homeScore !== this.props.homeScore
    ) {
      let awayScoreUpdate = false;
      let homeScoreUpdate = false;
      if (prevProps.awayScore && this.props.awayScore && prevProps.awayScore !== this.props.awayScore) {
        awayScoreUpdate = true;
      }
      if (prevProps.homeScore && this.props.homeScore && prevProps.homeScore !== this.props.homeScore) {
        homeScoreUpdate = true;
      }
      this.setState(
        {
          matchTime: this.getMatchTimeFromProps(),
          awayScoreUpdated: awayScoreUpdate,
          homeScoreUpdated: homeScoreUpdate,
        },
        () => {
          if (this.state.awayScoreUpdated || this.state.homeScoreUpdated) {
            setTimeout(() => this.setState({ awayScoreUpdated: false, homeScoreUpdated: false }), 3000);
          }
        }
      );
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.timeoutId);
  }

  getMatchTimeFromProps() {
    if (MatchHelper.isMatchLive(this.props.status)) {
      if (MatchHelper.isMatchPaused(this.props.matchStatus)) {
        return TranslationStore.getTranslation('live_match_status_half_time');
      } else if (this.isPenalty(this.props)) {
        return 'Straffar';
      } else if (MatchHelper.isMatchHockey(this.props.sportId)) {
        return MatchHelper.getHockeyLiveMatchStatus(this.props.matchStatus)
          ? MatchHelper.getHockeyLiveMatchStatus(this.props.matchStatus)
          : TranslationStore.getTranslation('live_match_status_live');
      } else if (this.props.elapsed && !MatchHelper.isMatchClosed(this.props.status) && MatchHelper.isMatchLive(this.props.status)) {
        if(this.props.elapsed && this.props.elapsed > 0) {
          if(this.props.injuryElapsed && this.props.injuryElapsed > 0) {
            return `${this.props.elapsed}+${this.props.injuryElapsed}'`
          } else {
            return `${this.props.elapsed}'`
          }
        }
         else if (this.isPeriodStartEvent(this.props)) {
          return (
            this.getDurationFromLastEventInMinutes(this.props) +
            this.getLatestEventMatchTimeForPeriodStart(this.props) +
            "'"
          );
        } else if (this.isSecondPeriodOvertime(this.props)) {
          return 105 + this.getDurationFromLastEventInMinutes(this.props) + "'";
        } else if (this.isFirstPeriodOvertime(this.props)) {
          return 91 + this.getDurationFromLastEventInMinutes(this.props) + "'";
        } else if (this.isSecondHalf(this.props)) {
          return 46 + this.getDurationFromLastEventInMinutes(this.props) + "'";
        } else {
          return '';
        }
      } else {
        return '';
      }
    } else {
      if (this.props.matchStatus === 'interrupted') {
        return TranslationStore.getTranslation('live_match_status_interrupted');
      }

      switch (this.props.status) {
        case 'postponed':
          return TranslationStore.getTranslation('live_match_status_postponed');
        case 'abandoned':
          return TranslationStore.getTranslation('live_match_status_abandoned');
        case 'cancelled':
          return TranslationStore.getTranslation('live_match_status_cancelled');
        case 'interrupted':
          return TranslationStore.getTranslation('live_match_status_interrupted');
        case 'delayed':
          return TranslationStore.getTranslation('live_match_status_delayed');
        case 'start_delayed':
          return TranslationStore.getTranslation('live_match_status_start_delayed');
        case 'closed':
        case 'ended':
          if (this.props.matchStatus === 'aet') {
            return TranslationStore.getTranslation('live_match_status_closed_after_extra_time');
          } else if (this.props.matchStatus === 'ap') {
            return TranslationStore.getTranslation('live_match_status_closed_after_penalties');
          } else {
            return TranslationStore.getTranslation('live_match_status_short_closed');
          }
        default:
          return this.isValidSchedule(this.props)
            ? moment(this.props.scheduled)
                .local() /*((DateHelper.UTCToLocalDate(this.props.scheduled)))*/
                .format('HH:mm')
            : '';
      }
    }
  }

  getLatestEventMatchTimeForPeriodStart(props) {
    if (this.isFirstHalf(props)) {
      return 1;
    } else if (this.isSecondHalf(props)) {
      return 46;
    } else if (this.isFirstPeriodOvertime(props)) {
      return 91;
    } else if (this.isSecondPeriodOvertime(props)) {
      return 106;
    }

    return 0;
  }

  isPeriodStartEvent(props) {
    return props.timeLines?.latestEventType === 'period_start' ? true : false;
  }

  isSecondPeriodOvertime(props) {
    return props.timeLines?.latestEventPeriodType === 'Overtime' && props.timeLines?.latestEventPeriod === 4 ? true : false;
  }

  isFirstPeriodOvertime(props) {
    return props.timeLines?.latestEventPeriodType === 'Overtime' && props.timeLines?.latestEventPeriod === 3 ? true : false;
  }

  isSecondHalf(props) {
    return props.timeLines?.latestEventPeriodType === 'regular_period' && props.timeLines?.latestEventPeriod === 2 ? true : false;
  }

  isFirstHalf(props) {
    return props.timeLines?.latestEventPeriodType === 'regular_period' && props.timeLines?.latestEventPeriod === 1 ? true : false;
  }

  isPenalty(props) {
    return props.timeLines?.latestEventPeriodType === 'Penalties' && props.timeLines?.latestEventPeriod === 5 ? true : false;
  }

  isLatestEventTimeAvailable(props) {
    return props.timeLines?.latestEventTime ? true : false;
  }

  isLatestEventMatchTimeAvailable(props) {
    return props.timeLines?.latestEventMatchTime !== null && props.timeLines?.latestEventMatchTime >= 0 ? true : false;
  }

  isValidSchedule(props) {
    return props.scheduled ? (DateHelper.UTCToLocalDate(props.scheduled) instanceof Date ? true : false) : false;
  }

  getDurationFromLastEventInMinutes(props) {
    let currentTime = moment().utc();
    let lastEventTimeStamp =
      props.timeLines?.latestEventTime.charAt(props.latestEventTime?.length - 1) === 'Z'
        ? moment(props.timeLines?.latestEventTime)
        : moment(props.timeLines?.latestEventTime + 'Z');
    let durationFromLastEvent = moment.duration(currentTime.diff(lastEventTimeStamp));

    return Math.floor(durationFromLastEvent.asMinutes() + 1);
  }

  renderMatchItemTopContainer() {
    const { tvLogo, tournamentName, sportName, showDateTimes } = this.props;
    const tvLogoSrc = tvLogo && tvLogo.file && tvLogo.file.url ? tvLogo.file.url : tvLogo?.length > 0 ? tvLogo : null;

    const { matchTime } = this.state;

    return (
      <div className={styles.topContainer}>
        <div className={styles.topLeftContainer}>
          <div className={styles.matchTimeContainer}>
            <span className={[styles.matchTime, this.props.status, !matchTime ? styles._hidden : ''].join(' ')}>
              {matchTime}
            </span>
          </div>
          {showDateTimes && <div className={styles.matchDate}>
            {moment(this.props.scheduled)
                .local() /*((DateHelper.UTCToLocalDate(this.props.scheduled)))*/
                .format('DD.MM.YYYY HH:mm')}
          </div> }
          <div className={styles.sportAndTournamentContainer}>
            <span className={styles.leagueName}>{sportName == 'Soccer' ? 'Fotboll' : sportName}</span>
            <div className={styles.divider} />
            <span className={styles.leagueName}>{tournamentName}</span>
            {this.props.isHasCustomTexts && <img className={styles.analizIcon} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1702346018/MCM/bg8mhvf1ervmmd1aziil.jpg'></img>}
            {this.props.isHasBetSuggestion && <img className={styles.betIcon} src='https://res.cloudinary.com/dzykt0nza/image/upload/v1702346018/MCM/xykkgc1jynhffxncqc6p.jpg'></img>}
          </div>
        </div>
        {tvLogoSrc ? (
          <div className={styles.tvLogoContainer}>
            <img src={tvLogoSrc} className={styles.tvLogo} style={{ 
            width: (this.props.customTvLogoWidth == null || this.props?.customTvLogoWidth < 1) ? '1.5em' : `${this.props.customTvLogoWidth}px`,
            height: (this.props.customTvLogoHeight == null || this.props?.customTvLogoHeight < 1) ? '1em' : `${this.props.customTvLogoHeight}px`, 
            minHeight: (this.props.customTvLogoHeight == null || this.props?.customTvLogoHeight < 1) ? '1em' : `${this.props.customTvLogoHeight}px`,
            maxHeight: (this.props.customTvLogoHeight == null || this.props?.customTvLogoHeight < 1) ? '1em' : `${this.props.customTvLogoHeight}px`,
            minWidth: (this.props.customTvLogoWidth == null || this.props?.customTvLogoWidth < 1) ? '1.5em' : `${this.props.customTvLogoWidth}px`,
            maxWidth: (this.props.customTvLogoWidth == null || this.props?.customTvLogoWidth < 1) ? '1.5em' : `${this.props.customTvLogoWidth}px`}} alt='' />
          </div>
        ) : null}
      </div>
    );
  }

  renderTeamScore(score, scoreUpdated) {
    if (score === undefined || score === null) {
      return null;
    }
    return (
      <span className={[styles.teamScore, scoreUpdated ? styles._highlighted : styles._notHighlighted].join(' ')}>
        {score !== undefined || score !== null ? score : ''}
      </span>
    );
  }

  renderMatchItemCenterContainer() {
    let homeTeamName = this.props.homeTeamDisplayName ? this.props.homeTeamDisplayName : this.props.homeTeamName ? this.props.homeTeamName : this.props.competitorNameHome;
    let awayTeamName = this.props.awayTeamDisplayName ? this.props.awayTeamDisplayName : this.props.awayTeamName ? this.props.awayTeamName : this.props.competitorNameAway;

    if (this.props.homeTeamNameOverride) {
      homeTeamName = this.props.homeTeamNameOverride;
    }

    if (this.props.awayTeamNameOverride) {
      awayTeamName = this.props.awayTeamNameOverride;
    }
    const competitorIdHome = (this.props.competitorIdHome ? this.props.competitorIdHome : this.props.homeTeamId) ?? this.props.competitorHome;
    const competitorIdAway = (this.props.competitorIdAway ? this.props.competitorIdAway : this.props.awayTeamId) ?? this.props.competitorAway;

    const { homeScore, awayScore, sport, tournamentLogo } = this.props;
    const tournamentLogoDefault = tournamentLogo;
    const tournamentLogoClass = LogoHelper.getTournamentLogoClass(this.props.tournamentId);
    const finalHomeScore =
      this.props.finalHomeScore === 0 || this.props.finalHomeScore ? this.props.finalHomeScore : homeScore;
    const finalAwayScore =
      this.props.finalAwayScore === 0 || this.props.finalAwayScore ? this.props.finalAwayScore : awayScore;
    return (
      <div className={styles.centerContainer}>
        {tournamentLogoDefault == null ? 
        tournamentLogoClass && <div className={[tournamentLogoClass, styles.tournamentLogo].join(' ')} />
        : <img className={styles.tournamentLogo} src={tournamentLogoDefault}></img>}
        <div className={styles.teams}>
          <div className={[styles.team, styles.team_home].join(' ')}>
            {this.renderTeamScore(finalHomeScore, this.state.homeScoreUpdated)}
            {competitorIdHome ? <img src={this.props.homeTeamLogo} className={styles.teamLogo} /> : ''}
            <span style={{color:  this.props.teamMoveOn == 1 && 'mediumseagreen' || '#000000'}}>{homeTeamName}</span>
          </div>
          <div className={styles.team}>
            {this.renderTeamScore(finalAwayScore, this.state.awayScoreUpdated)}
            {competitorIdAway ? <img src={this.props.awayTeamLogo} className={styles.teamLogo} /> : ''}
            <span style={{color:  this.props.teamMoveOn == 2 && 'mediumseagreen' || '#000000'}}>{awayTeamName}</span>
          </div>
          {this.props?.betSuggestionType != null && <div className={styles.centerBlock}>
            {EbettingType.find(el => el.id == this.props.betSuggestionType).name} : {this.props?.betSuggestionValue}
          </div>}
        </div>
        <img className={styles.sportsType} src={`/images/sportsicons/${this.props.sportName}.svg`} alt='' />
      </div>
    );
  }

  render() {
    const slug = this.props.isMatchPage ? '' : '/' + MatchHelper.getSlugFromMatch(this.props)
    const matchTabAlias = this.props.selectMatchTabByAlias
      ? `?match_tab_alias=${this.props.selectMatchTabByAlias}`
      : '';

    return (
        <LinkWrapper link={!this.props.notLink ? slug + matchTabAlias : null} className={styles.matchLink} id={this.props.id}>
          <div
            data-match-id={this.props.id}
            className={[
              styles.matchItem,
              this.state.awayScoreUpdated || this.state.homeScoreUpdated ? styles._highlighted : styles._notHighlighted,
            ].join(' ')}>
            {this.renderMatchItemTopContainer()}
            {this.renderMatchItemCenterContainer()}
          </div>
        </LinkWrapper>
    );
  }
}
